// import 'modern-css-reset/dist/reset.min.css';
import '../scss/style.scss';

import {Loader} from './loader.js';
import {ToTop} from './toTop.js';
import {HeaderController} from './headerController.js';
import {SmoothScroll} from './smoothScroll.js';

const loader = new Loader('loader');
const toTop = new ToTop('toTop');
const headerController = new HeaderController();
const smoothScroll = new SmoothScroll();