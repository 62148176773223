export class ToTop {

  #toTopElm;
  #hidePointY;
  #showPointY;
  #previousY = 0;
  #previous2Y = 0;

  constructor(id) {
    this.#toTopElm = document.getElementById(id);
    this.#hidePointY = document.documentElement.clientHeight / 2;
    this.#showPointY = document.documentElement.scrollHeight - document.documentElement.clientHeight * 1.5;
    this.setEventListener();

  }

  setEventListener() {
    window.addEventListener("scroll", () => {
      this.scrollFunction();
    })
  }

  scrollFunction() {
    const currentY = window.scrollY;

    let isScrollDown = this.isScrollDown();
    let isExcludeArea = this.isExcludeArea();
    let isIncludeArea = this.isIncludeArea();


    if (isIncludeArea) {
      this.#toTopElm.classList.add('js-visible');
    } else if (isScrollDown || isExcludeArea) {
      this.#toTopElm.classList.remove('js-visible');
      // console.log("scrolldown:" + isScrollDown + " | exclude: " + isExcludeArea);
    } else {
      this.#toTopElm.classList.add('js-visible');
    }

    this.#previous2Y = this.#previousY;
    this.#previousY = currentY;

  }

  // scrollDownしたか判定する
  isScrollDown() {
    const currentY = window.scrollY;
    // ２回連続で下がっていたらtrue
    let result = !(this.#previous2Y > this.#previousY && this.#previousY > currentY);
    // console.log("prev2:"+ this.#previous2Y + "｜prev1:" + this.#previousY + "｜current:" + currentY);
    // console.log(result);
    return result;
  }

  // 除外エリアの判定
  isExcludeArea() {
    const currentY = window.scrollY;
    let isExcludeArea = this.#hidePointY > currentY;
    // console.log("start:" + this.#hidePointY + "｜current:" + currentY);
    // console.log(isExcludeArea);

    return isExcludeArea;
  }

  // 包含エリア判定
  isIncludeArea() {
    const currentY = window.scrollY;
    let isIncludeArea = this.#showPointY < currentY;
    // console.log("show:" + this.#showPointY + "｜current:" + currentY);
    // console.log(isIncludeArea);

    return isIncludeArea;
  }
}