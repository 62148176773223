export class HeaderController {

  #header;
  #logo;
  #hamburger;
  #modal;

  constructor(name) {
    this.setElements();
    this.setNarrowHeader();
    this.modalControler();
  }


  setElements() {
    this.#header = document.getElementById("header");
    this.#logo = this.#header.querySelector('.headerLogo');
    this.#hamburger = document.getElementById('hamburgerBtn');
    this.#modal = document.getElementById('modal');
  }

  setNarrowHeader() {
    window.addEventListener("scroll", () => {

      // スクロール位置が特定の値を超えたらクラスを追加
      if (window.scrollY > 600) {
        this.#header.classList.add("js-narrow");
      } else {
        // スクロール位置が特定の値より小さい場合はクラスを削除
        this.#header.classList.remove("js-narrow");
      }
    });

  }

  modalControler() {
    this.#hamburger.addEventListener('click', (e) => {
      // クリックイベントを無効化
      e.preventDefault();
      // 各要素にクラスを追加・削除
      if (this.#hamburger.classList.contains('js-open')) {
        this.#hamburger.classList.remove('js-open');
        this.#modal.classList.remove('js-active');
      } else {
        this.#hamburger.classList.add('js-open');
        this.#modal.classList.add('js-active');
      }
    })

    const $linkElement = this.#modal.querySelectorAll('a');
    $linkElement.forEach(element => {
      element.addEventListener('click', (e) => {
        this.#hamburger.classList.remove('js-open');
        this.#modal.classList.remove('js-active');
      })
    });

  }
}