import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export class Loader {

  #loaderElm;
  #logoAnimateEnd = false;
  #windowLoaded = false;
  #mainTimeline;
  #visited = false;
  #debug = false;

  constructor(id) {
    this.#loaderElm = document.getElementById(id);
    this.setupEventListeners();
    this.#mainTimeline = gsap.timeline();
  }

  setupEventListeners() {
    document.addEventListener('DOMContentLoaded', this.set.bind(this));
    window.addEventListener('load', this.loaded.bind(this));
  }

  set() {
    // 訪問済みなら表示しない & #debugがtrueなら表示する
    this.#visited = sessionStorage.getItem('visited');
    if (this.#visited && !this.#debug) {
      return false;
    }

    this.#loaderElm.classList.add('active');

    //スクロールしないようにする
    document.body.style.overflow = 'hidden';
    // logoSvgのcssアニメーションをセット
    // this.logoStart();


  }

  loaded() {
    document.getElementsByTagName('body')[0].classList.remove('js-preload');
    this.#windowLoaded = true;
    this.start();
  }


  start() {

    //訪問済みを記録
    sessionStorage.setItem('visited', true);

    // ローダー画面を消去
    this.#mainTimeline.to('#loader', {
      opacity: 0,
      display: 'none',
      duration: 1,
      delay: 1,
      ease: 'power4 out',
    })
      .eventCallback("onComplete", () => {
        this.complete()
      })
  }

  complete() {
    //スクロールできるようにする
    document.body.style.overflow = 'visible';
  }
}